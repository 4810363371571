export default function Configs() {
  const searchItems = [
    {
      label: "测点",
      span: 8,
      model: "point",
      clearable: true,
      type: "cascader",
      options: [],
      showAllLevels:false,
      props:{
        checkStrictly: true,
        label:'name',
        value:'sign',
        children:'children',
      }
    },
    {
      label: "类型",
      span:8,
      type: "select",
      model: "type",
      options: [
        {label: '结构构件',value:0},
        {label: '结构整体',value:1}
      ],
      clearable: true,
    },
    {
      label: "警报等级",
      span:8,
      type: "select",
      model: "level",
      options: [
        {label: '一级',value:1},
        {label: '二级',value:2},
        {label: '三级',value:3}
      ],
      clearable: true,
    },
  ];
  const tableColumns = [
    {
      label: "测点名称",
      prop: "sensorDetailName",
      align: "center",
      show: true
    },
    {
      label: "传感器名称",
      prop: "sensorName",
      align: "center",
      show: true
    },
    {
      label: "结构类型",
      prop: "type",
      align: "center",
      show: true
    },
    {
      label: "警报等级",
      prop: "warnLevel",
      align: "center",
      show: true
    },
    {
      label: "警报时间",
      prop: "datetime",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    searchItems,
    tableColumns
  };
}
