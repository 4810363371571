<template>
  <div class="health">
    <div class="health-left">
      <div class="health-top">
        <div class="health-title">
          <i class="el-icon-s-order"></i>
          <span>结构构件健康度</span>
        </div>
        <div class="health-img">
          <img :src="componentImg" alt="">
          <span :style="'color:' + componentColor">{{componentHealth}}</span>
        </div>
      </div>
      <div class="health-bot">
        <div class="health-title">
          <i class="el-icon-s-order"></i>
          <span>结构整体健康度</span>
        </div>
        <div class="health-img">
          <img :src="wholeImg" alt="">
          <span :style="'color:' + wholeColor">{{wholeHealth}}</span>
        </div>
      </div>
    </div>
    <div class="health-right">
      <div class="tit-search flex flex-end">
        <c-search
            v-model="pageParams"
            :searchItems="searchItems"
            @search="search()"
        ></c-search>
      </div>
      <div class="table-wrapper" >
        <c-table
            :index="false"
            :data="pageData"
            :tableColumns="tableColumns"
            :stripe="true"
            :pagination="pageParams"
            @change="pageGet"
        >
          <template v-slot:custom="{ row }">
            <span class="opt-detail" @click="getDetail(row)">详情</span>
          </template>
        </c-table>
      </div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="详情"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="1100px"
    >
      <div class="health-chart">
        <chart-one ref="chartOne" v-loading="loading" style="height: 400px" :value="chartData"></chart-one>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import Configs from "./config/index";
  import chartOne from "./chart/chart01"
  let mPage = MPage({
    pagePath: "/health/assess/warn/listPage",
    pageDelPath: "",
    pageDelsPath: "",
    pageParams: {},
  });
  export default {
    name: "index",
    mixins: [mPage],
    components:{chartOne},
    data(){
      let { searchItems,tableColumns } = Configs(this);
      return{
        searchItems,
        tableColumns,
        dialogVisible: false,
        pageData:[],
        chartData: {
          legend:[],
          markLineList:[]
        },
        loading:false,
        form:{
          model:{},
          rules:{
            type:[
              { required: true, message: '请选择类型', trigger: 'blur' }
            ],
            sensorId:[
              { required: true, message: '请选择传感器', trigger: 'blur' }
            ],
            sensorDetailId:[
              { required: true, message: '请选择测点', trigger: 'blur' }
            ]
          }
        },
        componentHealth: "",
        componentImg:"",
        componentColor:"",
        wholeHealth: "",
        wholeImg:"",
        wholeColor:"",
      }
    },
    created() {
      this.sensorTree()
      this.getResult()
    },
    methods:{
      sensorTree(){
        this.$http.get('/health/assess/sensorTree').then(res => {
          if (res.success){
            this.searchItems[0].options = res.data
          }
        })
      },
      getResult(){
        this.$http.get('/health/assess/result').then(res => {
          if (res.success){
            this.componentHealth = res.data.componentHealth
            switch (this.componentHealth) {
              case "轻微异常":
                this.componentImg =  require('@/assets/images/single/screen03/yellow.png')
                this.componentColor = '#E2BC35'
                break;
              case "中等异常":
                this.componentImg =  require('@/assets/images/single/screen03/orange.png')
                this.componentColor = '#F79234'
                break;
              case "严重异常":
                this.componentImg =  require('@/assets/images/single/screen03/red.png')
                this.componentColor = '#F53737'
                break;
              default:
                this.componentImg =  require('@/assets/images/single/screen03/green.png')
                this.componentColor = '#4DE1B4'
            }
            this.wholeHealth = res.data.wholeHealth
            switch (this.wholeHealth) {
              case "轻微异常":
                this.wholeImg =  require('@/assets/images/single/screen03/yellow.png')
                this.wholeColor = '#E2BC35'
                break;
              case "中等异常":
                this.wholeImg =  require('@/assets/images/single/screen03/orange.png')
                this.wholeColor = '#F79234'
                break;
              case "严重异常":
                this.wholeImg =  require('@/assets/images/single/screen03/red.png')
                this.wholeColor = '#F53737'
                break;
              default:
                this.wholeImg =  require('@/assets/images/single/screen03/green.png')
                this.wholeColor = '#4DE1B4'
            }
          }
        })
      },
      search(){
        let arr = this.searchItems[0].options
        if (this.pageParams.point && this.pageParams.point.length > 0){
          let id = this.pageParams.point[this.pageParams.point.length - 1]
          this.getSensorAttr(id,arr)
        } else {
          this.pageParams.isRoot = ''
          this.pageParams.monitorId = ''
          this.pageParams.pointId = ''
        }
        this.pageGet(true)
      },
      getSensorAttr(id,arr){
        for(let item of arr){
          if (item.sign === id){
            this.pageParams.isRoot = item.isRoot
            this.pageParams.monitorId = item.monitorId
            this.pageParams.pointId = item.pointId
            break
          } else if(item.children && item.children.length > 0){
            this.getSensorAttr(id,item.children)
          }
        }
      },
      getDetail(data){
        let self = this
        self.loading = true
        self.$http.post('/health/assess/warn/detail',{pointId:data.sensorDetailId,dateTime:data.datetime}).then(res => {
          if (res.success){
            if (res.data){
              res.data.colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
              self.chartData = res.data
            }
            self.dialogVisible = true
          } else {
            this.$message.error(res.mag)
          }
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped>
  .health-title{
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 16px;
    /*border-bottom: 1px solid #efefef;*/
    margin-bottom: 10px;
  }
  .health-title i{
    color: #1A67D0;
    font-size: 20px;
    margin-right: 5px;
  }
  .health-title span{
    color: #1A67D0;
    font-weight: bold;
  }
  .health{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .health-left{
    width: 25%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .health-top, .health-bot{
    width: 100%;
    height: 49%;
    background: #fff;
    padding: 10px 20px;
  }
  .health-img{
    width: 200px;
    height: 160px;
    margin : 100px auto 0;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .health-img img{
    width: 100%;
    height: 100%;
  }
  .health-img span{
    display: block;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    top: 65px;
    font-size: 22px;
    font-weight: bold;
    color: #4DE1B4;
  }
  .health-right{
    width: 74%;
    height: 100%;
    background: #fff;
    padding: 20px;
  }
  .table-wrapper{
    height: calc(100% - 50px);
  }
</style>